.FormSuccess {
    background-color: #f6f6f6;
    padding-top: 20px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.msg_box {
    width: max-content;
    background-color:#0080001f;
    border-radius: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 20px 40px;
}

.msg {
    color: green;
    font-weight: 500;
}