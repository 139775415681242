.svg_container {
	display: flex;
	margin-left: 20px;
}

svg {
	font-size: 36px;
	margin: 0 5px;
}

.firstName{
	font-weight: bold;
}

.lastName{
	fill: #5f6368;
}
