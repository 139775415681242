*, ::before, ::after {
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgb(59 130 246 / 0.5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
}

form {
    background-color: #f6f6f6;
    padding-top: 20px;
    height: 100%;
}

.form_grid {
    width: max-content;
    margin: 0 auto;
}

.form_box {
    width: min-content;
    border: none;
    border-radius: 10px;
    margin: 0 auto;
    padding: 20px;
    background-color: white;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.input_label {
    display: block;
    margin: 30px 10px;
    width: max-content;
    text-align: left;

}

.label {
    font-weight: 500;
    color: rgb(17, 24, 39);
    font-size: 1rem;
    line-height: 1.5rem;
}

.input_box {
    --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
    border-radius: 0.375rem;
    font-size: 0.875rem;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
    /*
    border-color: #6b7280;
    */
    border-style: solid;
    border-color: #e5e7eb;
    --tw-ring-inset: inset;
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(209 213 219 / var(--tw-ring-opacity));
    border-width: 1px; 
    box-sizing: border-box;
}

.input {
    padding-left: 0.25rem;
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
    line-height: 1.5rem;
    background-color: transparent;
    border-width: 0px;
    --tw-text-opacity: 1;
    color: rgb(17 24 39 / var(--tw-text-opacity));
}

.validation_error {
    margin-top: 10px;
    color: red;
}

.textarea_ipt {
    width: 500px;
    height: 100px;
}

.form_btn {
    padding: 10px 20px;
    background-color:#3378df; 
    border: none;
    border-radius: 50px;
    width: max-content;
    color: white;
    font-weight: bold;
    font-size: 1rem;
    margin-top: 40px;
}

.form_btn:hover {
    background-color: #004dbf;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    cursor: pointer;
}