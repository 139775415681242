.first_container, .third_container, .fourth_container {
	/*
	max-width: 960px;
	*/
	margin: 0 auto;
	max-width: 65rem;
}

.profile_pic, .experience_rph_pic{
	margin-top: 50px;
	width: 400px;
	height: 400px;
	object-fit: cover;
	border-radius: 50%;
}

.hoverBubble {
	background: #333333;
	color: #ffffff;
	padding: 5px;
	position:absolute;
	text-align: left;
	border-radius: 3px;
}


.experience_tech_pic  {
	margin-top: 50px;
	height: 400px;
	width: 400px;
	border-radius: 50%;
}

.img_box {
	height: 400px;
	width: 400px;
	border-radius: 50%;
}


.about_me_container, .experience_container {
	display: flex;
	height: 500px;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 50px;

}

.about_me_text_container{
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	margin-left: 50px;
}

.experience_text_container {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.h1_about_me  {
	font-size: 48px;
}

.p_about_me {
	color: #414549;
	font-size: 32px;
}


.second_container_spanning{
	width: 100%;
	background: black;
	/*
	height: 400px;
	*/
	height: max-content;
}

.inner_second_container {
	width: min(65rem, 90%);
	height: max-content;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.second_container_h1, .experience_h1{
	color: #f5f5f7;
	/*
	font-size: 56px;
	*/
	line-height: 1.0714285714;
	font-weight: 600;
	letter-spacing: -0.005em;
	width: max-content;
}

.skills_h1 {
	display: inline-block;
}

.experience_h1, .education_h1, .skills_h1, .testimonials_h1   {
	color: black;
	font-size: 56px;
	line-height: 1.0714285714;
	font-weight: 600;
	letter-spacing: -0.005em;
	width: max-content;
}

.testimonials_h1 {
	margin: auto;
}

.education_h1, .skills_h1 {
	margin: 20px auto;
}
.second_container_text {
	color: #a1a1a6;
	font-size: 24px;
	line-height: 1.16667;
	font-weight: 600;
	letter-spacing: 0.009em;
	height: min-content;
	margin: 0;
	padding: 0;
	/*
	max-width: 83.3333333333%;
	*/
	text-align: center;
}

.highlight {
	color: white;
}

.experience_rph_list, .experience_tech_list {
	color: #414549;
	display: flex;
	font-size: 24px;
	line-height: 1.16667;
	font-weight: 600;
	letter-spacing: 0.009em;
	text-align: left;
	height: 100%;
	flex-direction: column;
}

.experience_rph_h2, .experience_tech_h2 {
	margin-bottom: 0;
	max-width: 65rem;
}

.experience_rph_ul, .experience_tech_ul {
	display: block;
	margin: 0;
}

.experience_rph_li, .experience_tech_li {
	margin: 5px 0;
}

.experience_rph_p, .experience_tech_p {
	text-indent: 25px;
	font-size: 18px;
	font-weight: 400;
	width: 90%; 
}

.edu_logo {
	height: 150px;
	
}

.edu_ric_container, .edu_uconn_container {
	display: flex;
	justify-content: space-evenly;
	align-items: center; 
	margin-bottom: 20px;
	/*
	width: 960px;
	*/
	max-width: 65rem;
	margin: 0 auto;
}
	
.fifth_container, .sixth_container {
	background-color: #f8f8f8;	 

	padding: 20px 0;
}

.edu_text_container {
	width: 200px;
	display: flex;
	flex-direction: column;
}

.edu_ul {
	display: block;
	text-align: left;
	padding-left: 0;
}

.edu_h1, .edu_li {
	width: max-content;
	margin-top: 0;
}

.education_h1 .skills_h1 {
	color: black;
	font-size: 56px;
	line-height: 1.0714285714;
	font-weight: 600;
	letter-spacing: -0.005em;
	width: max-content;
	margin: 0 auto;
	margin-bottom: 20px;
}



.edu_ric_h1 {
	color: #601923;
}

.edu_uconn_h1 {
	color: #000E2F;
}

.seventh_container {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	background-color: #f8f8f8;	 
}

.skills_button_and_logos, .scrollButtons_scrollContent_box {
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	width: 100%;
}


.skills_container {
	/*
	width: 960px;
	*/
	max-width: 60rem;
	display: flex;
	height: max-content;
	overflow-x: hidden;
}

.skills_logo {
	height: 200px;
	width: 200px;
	margin: 25px;
}

.eigth_container {
	height: 800px;
	display: flex;
	justify-content: space-evenly;
}

.forward_arrow_container, .back_arrow_container {
	display: flex;
	height: 100%;
	align-items: center;
	justify-content: center;
}

.img_shadow {
	box-shadow: rgba(0,0,0, 0.8) 0px 15px 25px 0px;

}

.horizontal_scroll_box {
	height: 100%;
	width: 960px;
	text-align: center;
}
.testimonial_box {
	height: 100%;
	width: 100%;
	text-align:center;
} 

.testimonial_cards {
	/*
	width: 100%;
	we should specify finite length so we can set "overflow-x" property.
	*/
	width: 960px;
	display: flex;
	/*
	justify-content: center;
	lol, the above declaration gave us this weird behavior where the leftmost element/component was cut/clipped off.
	luckily, i came across this SO post with the same issue 
	https://stackoverflow.com/questions/67355972/overflow-scroll-not-showing-everything-in-div-some-part-of-the-left-side-is-sti?rq=1
	it's edge cases like this that i'm afraid of.
	*/
	overflow-x: hidden;
	padding: 20px;
	align-items: center;
}


.footer_wrapper {
	background-color: #0d1117;
	height: 100px;

}
.footer {
	margin: 0 auto;
	/*
	max-width: 1280px;
	changing from fixed to relative units instead for more responsive design
	*/
	max-width: 70rem;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.footer_col_one {
	color: white;
	flex-shrink: 1;
}

.footer_col_one span {
	font-weight: bold;
	font-size: large;
}

.react_span {
	color: rgb(8 126 164);
}

.nginx_span {
	color: #099639;
}

.aws_span {
	color: #ff9d00;
}

.social_wrapper {
	display: flex;
	width: min-content;
	align-items: center;
}

.nav_icon {
	color: #6e7274;
	width: 40px;
	margin: 5px;
}

.nav_icon:hover {
	color: white;
}

.svg-inline--fa.fa-w-16 {
	width: 1em;
	height: 1em;
}


@media screen and (max-width: 40rem) {
	.about_me_container, .experience_container {
		flex-direction: column;
		height: max-content;
	}

	.second_container_text {
		width: min(90%, 65rem);
	}

	.edu_uconn_container, .edu_ric_container {
		justify-content: flex-start;
	}

	.edu_uconn_container img, .edu_ric_container img {
		display: none;
	}

	.edu_text_container {
		margin-left: 1rem;
	}

	.experience_rph_ul, .experience_tech_ul {
		max-width: 90%;
	}
	

}