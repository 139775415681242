.navigation {
	margin: 0 auto;
	background-color: white;
	border-bottom: 1px solid #dadce0;
	/*
	display: flex;
	justify-content: space-evenly;
	box-shadow: 0 1px 2px 0;
	height: 64px;
	height: 4rem;
	*/
	min-height: 4rem;
	box-sizing: border-box;
	top: 0;
	position: sticky;
	width: 100%;
}

.inner_nav_container {
	display: flex;
	margin: 0 auto;
	max-width: 60rem;
	height: 4rem;
	justify-content: space-between;
}

.anchor_container {
	margin-right: 20px;
	display:flex;
	visibility: visible;
}

@media screen and (max-width:40em) {
	.anchor_container {
		/*
		visibility: collapse;
		*/
		display: none;
	}

	
}



ul {
	height: 100%;
	display: flex;
	list-style-type: none;
	margin: 0;
	width: 100%;
}

.active_menu {
	display: block;
	position: absolute;
	top: 5rem;
	left: 0rem;
	z-index: 10;
	background-color: white;
	width: 100%;
}

@media screen and (min-width: 40rem) {
	.active_menu {
		display: none;
	}
}


.inactive_menu {
	display: none;
}

.ul_mobile {
	display: flex;
	list-style-type: none;
	flex-direction: column;
	padding: 0;
}

.ul_mobile a {
	padding: 2rem;
	font-size: 2rem;
	border-bottom: 1px solid black;
	color: inherit;
}

.mobile_menu_link {
	text-decoration: none;
}


.anchor {
	text-decoration: none;
	/*
	margin: 20px;
	border-bottom: 2px solid white;
	*/
	height: 100%;
	min-width: 120px;
	padding: 0 16px;
	justify-content: center;
	align-items: center;
	display: flex;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	box-sizing: border-box;
	color: #5f6368;
}

.anchor:visited {
	content: none;
}

.activeLink {
	text-decoration: none;
	height: 100%;
	min-width: 120px;
	padding: 0 16px;
	justify-content: center;
	align-items: center;
	display: flex;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	box-sizing: border-box;

	background: rgba(26,115,232,.1);
	border-bottom: 2px solid #1a73e8;
	color: #1a73e8;
}

.anchor--active{
	background: rgba(26,115,232,.1);
	border-bottom: 2px solid #1a73e8;
	color: #1a73e8;					
}	



.li_anchor {
	display: flex;
	height: 100%;
}


.list_item {
	display: flex;
	height: 100%;
}




.anchor:hover {
	color: rgb(0, 105, 255);
	background-color: rgba(32,33,36,.04);
	border-bottom: 2px solid #1a73e8;
}

.menu_icon, .close_icon {
	/*
	width: 2rem;
	height: 2rem;
	margin-top: auto;
	margin-bottom: auto;
	margin-right: 1rem;
	*/
	display: none;
}

.nav_icons_container {
	width: 5rem;
	display: flex;
	align-items: center;
	justify-content: center;
}


@media screen and (max-width:40em) {
	.menu_icon {
		display: block;
		visibility: visible;
		/*
		min-width: 100%;
		*/
		width: 2rem;
		height: 2rem; 
		margin: auto;
	}

	.close_icon {
		display: block;
		visibility: visible;
		width: 4rem;
		height: 4rem;
	}
}