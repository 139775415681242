
.card {
    width: 400px;
    height: 500px;
    border-radius: 20px;
    background-color: black;
	box-shadow: rgba(0, 0, 0, 0.23) 0px 15px 25px 0px;
    padding: 0;
    flex-shrink: 0;
    margin: 20px;
}



.card_img_top {
    margin: 0;
    padding: 0;
    /*
    width: 500px; 
    */
    max-width: 100%;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}


.card_body {
    padding-top: 40px;
    padding-left: 40px;
    padding-right: 40px;
    max-height: 100%;
}


.card_title {
    text-align: left;
    font-size: 1em;
    font-weight: bold;
    color: white;
    margin: 0;
}

.card_text {
    font-size: 1rem;
    font-weight: 100;
    color: grey;
}

.btn {
    border: 1px solid white;
    border-radius: 9999px;
    background-color: white;
    color: black;
    text-decoration: none;
    font-weight: bold;
}

.btn_primary {
    padding: 10px 20px;
    margin: 10px;
}

.active {
    /*
    transform: scale(125%);
    */
    width: 450px;
    height: 550px;
}